@import '../scss/common.scss';

.world-map {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  position: relative;
}

.world-map.show {
  max-height: 600px; /* Adjust as needed for the content */
}

.world-map.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: none;
  z-index: 9999;
}

.world-map iframe {
  width: 100%;
  height: 600px;
  border-radius: 20px;
  padding: auto;
}

.world-map.full-screen iframe {
  height: 100%;
}

.fullscreen-button {
  @extend .button;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.fullscreen-button svg {
  width: 24px;
  height: 24px;
  fill: #ffffff;
}

.fullscreen-button:hover {
  background: rgba(46, 46, 46, 0.8);
  scale: 1;
}
