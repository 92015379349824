.button {
    background-color: #4a4a6a;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #6b6b8f;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
}

.header img {
    width: 100px;
}

.header .site-title {
    font-size: 2rem;
    line-height: 30px;
    font-weight: bold;
    user-select: none;
}

.page-container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    object-fit: contain;
    background-color: rgba(44, 44, 62, 0);
}

.component-container {
    display: block;
    background: rgba(44, 44, 62, 0.9);
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}