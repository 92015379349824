@keyframes slideFadeIn {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomFadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

.page-enter {
    opacity: 0;
    transform: translateX(50px);
    visibility: hidden;
}

.page-enter-active {
    visibility: visible;
    animation: slideFadeIn 700ms ease-out forwards;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.page-exit-active {
    animation: zoomFadeOut 600ms ease-in forwards;
    visibility: hidden;
    animation-delay: 600ms; /* Delay hiding to allow for fade-out animation to complete */
}
