.site-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
    padding: 10px 20px;
    background: linear-gradient(90deg, #040416, #15152b);
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    font-family: Geneva, Verdana, sans-serif;
    height: 120px;
  }

  .logo-container {
    display: flex;
    align-items: center; /* Center items vertically */
    cursor: pointer;
  }

  .logo {
    height: 80px;
    margin-right: 10px;
  }

  .site-title {
    font-size: 2rem;
    line-height: 30px; /* Match the height of the logo */
    font-weight: bold;
    font-family: 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    user-select: none;
  }

  .toggle-container {
    display: flex;
    align-items: center;
  }

  .toggle-label {
    margin-right: 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
  }
  input:checked + .slider {
    background-color: #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }