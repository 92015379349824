@import './scss/common.scss';

.main-content {
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 10px;
  gap: 20px;
  width: 100%;
  justify-content:center;
  display: flex;
  flex: auto;
}

.discord-widget {
  @extend.component-container;
  border-radius:10px;
  display: flex;
  flex: auto;
}

.content-area {
  @extend .component-container;
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 10px;
}

.buttons {
  display: flex;
  flex: initial;
  max-height: min-content;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.buttons button {
  @extend .button;
}

.buttons button:hover {
  background-color: #6b6b8f;
  transform: scale(1.05);
}