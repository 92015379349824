/* Reset default margin and padding */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

/* Ensure the root element takes up the full height */
#root {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(180deg, rgba(30, 30, 47, 0.8), rgba(44, 44, 62, 0.8)),
              url('./assets/images/SpaceTrainClubhouse.png') no-repeat top padding-box;
  background-color: #161920;
  color: white;
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center; /* Center the main container */
  width: 100%;
  box-sizing: border-box;
}
