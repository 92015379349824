@import 'common.scss';

.modpack-info {
  text-align: center;
  margin: 20px;
  background: rgba(44, 44, 62, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  object-fit: contain;
}