@import '../scss/common.scss';

.file-list {
  @extend .component-container;
  padding: 0;
 // background-color: #373750;
}

.file-button {
  @extend .button;
  margin: 10px;
  padding: 10px 20px;
  background-color: linear-gradient(90deg, rgba(8, 8, 12, 1), rgba(44, 44, 62, 1));
  border: 1px solid #03070a;
  font-family: 'Roboto', sans-serif;
}

.file-button:hover {
  background-color: #6b6b8f;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(74, 74, 106, 0.8);
  border-radius: 8px;
}
.file-item a {
  color: #4a90e2;
  text-decoration: none;
}