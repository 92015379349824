@import 'common.scss';

.slideshow-container {
  @extend .component-container;
  display: flex;
  border-spacing: 10px;
  flex-direction: column;
  justify-items: flex-end;
  align-items: center;
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
  padding: 10px;
  overflow: hidden;
}

.slides-wrapper {
  display: flex;
  width: 100%;
  max-height: 500px;
}

.mySlides {
  min-width: 100%;
  width: 100%;
  object-fit: fill;
  opacity: 0;
}

.mySlides.active {
  opacity: 1;
}

.mySlides img {
  object-fit: fill;
  border-radius: 20px;
}

.arrow {
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  cursor: pointer;
  z-index: 10;
}

.right-arrow {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.left-arrow {
  left: 0;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.indicator-container{
  justify-content: center;
  align-content: center;
  position: relative;
  overflow-y:visible;
  max-width:fit-content;
  margin-top: auto;
}

.indicator-wrapper {
  height: max-content;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-content: center;
  position: relative;
  width: 100%;
}

.indicator {
  display: block;
  width: 2dvw;
  height: 0.5ex;
  min-width: 10px;
  min-height: 1px;
  max-width: 30px;
  max-height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 5px;
  position: relative;
  align-self: center;
  overflow: hidden;
  cursor: pointer;
}

.indicator.active {
  background-color: rgba(255, 255, 255, 0.8);
}

.indicator .progress {
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.1s linear;
}
.slideshow-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.play-pause{
  position: relative;
  margin-top:auto;
  top: 0;
  padding: 100px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}