.mcstatus-widget {
    text-align: center;
    margin-top: 20px;
  }

  .mcstatus-widget button {
    border: none;
    padding: 0;
    background: none;
  }

  .mcstatus-widget img {
    border: none;
  }
