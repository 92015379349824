@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional styles from index.css and styles.css */

header {
  position: sticky;
  top: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0;
  background-color: #1a1717;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
  max-width: 1200px;
  width: device-width;
  margin-left: auto;
  margin-right: auto;
}

#Banner {
  position: flex;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1a1717;
  z-index: 999;
  display: flex;
  align-items: center;
  padding: 5px;
}

#HomeButton {
  background-color: #0000;
}

#logo {
  background-color: #0000;
  max-height: 80px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 100px;
  background-color: #f4f4f4;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.dark-mode {
  background-color: #333;
  color: #f4f4f4;
}

.container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center the buttons */
}

.dark-mode .container {
  background-color: #333;
  color: #f4f4f4;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s; /* Add transform transition */
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Scale the button on hover to make it 'pop' */
}

a {
  text-decoration: none;
}

/* Minecraft world map drawer, should smoothly transition the max-height and visibility */
#mapDrawer {
  overflow: hidden;
  max-height: 0;
  position: relative;
}

.transitioning {
  transition: max-height 0.3s ease;
}

#fullscreenToggleIcon {
  position: absolute;
  cursor: pointer;
  bottom: 10px;
  right: 10px;
  height: 32px;
  width: 32px;
}

#mapDrawer iframe {
  border: 2px solid #0c0c0c;
  display: block; /* To remove any extra space around the iframe */
  width: 100%;
  height: 100%;
  visibility: hidden;
  transition: visibility 0.3s ease;
}

#mapDrawer.open {
  max-height: 1000px; /* Same value as the iframe height */
}

#mapDrawer.open iframe {
  visibility: visible;
}

.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-current:hover {
  background-color: currentColor;
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}

.hover\:bg-gradient-to-br:hover {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.hover\:bg-gradient-to-tr:hover {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.hover\:opacity-90:hover {
  opacity: 0.9;
}

.hover\:outline-double:hover {
  outline-style: double;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

:is(.dark .dark\:left-6) {
  left: 1.5rem;
}

:is(.dark .dark\:bg-blue-900) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-950) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gradient-to-t) {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

:is(.dark .dark\:from-stone-900) {
  --tw-gradient-from: #1c1917 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

:is(.dark .dark\:to-slate-900) {
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
}

:is(.dark .dark\:text-slate-500) {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:invert-0) {
  --tw-invert: invert(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 768px) {
  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:min-h-0 {
    min-height: 0px;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:p-0 {
    padding: 0px;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-\[\] {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-\[-10px\] {
    padding-left: -10px;
    padding-right: -10px;
  }
}
